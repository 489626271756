/******************************************************************
Site Name: 
Author: 

Stylesheet: 481px and Up Stylesheet

This stylesheet is loaded for larger devices. It's set to 
481px because at 480px it would load on a landscaped iPhone.
This isn't ideal because then you would be loading all those
extra styles on that same mobile connection. 

A word of warning. This size COULD be a larger mobile device,
so you still want to keep it pretty light and simply expand
upon your base.scss styles.

******************************************************************/

	

