/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/


.header .clearHeader {
    #logo {
        width: 250px;
        height: 103px;
        background-size: 250px 103px;
        @include transition(all 0.5s ease-in-out);
        background: transparent;
    }
}

.header {

    .clearHeader {

        .row {
            min-height: 145px;
        }

        #logo {
            width: 250px;
            height: 103px;
            background: transparent url('../images/logo_white-2x.svg') no-repeat center top;
            background-size: 250px 103px;
            @include transition(all 0.3s ease-in-out);
        }
    }

    .darkHeader {

        .row {
            min-height: 110px;
        }

        #logo {
            width: 250px;
            height: 103px;
            background: transparent url('../images/logo.svg') no-repeat center top;
            background-size: 250px 103px;
            @include transition(all 0.3s ease-in-out);
        }
    }
}



#content {height:auto;}

/*.slick-prev, .slick-next { top: 50%; }
.slick-next { right: 50px; }
.slick-prev { left: 50px; }
.slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }*/